.file-chit {
  display: flex;
  border-radius: 15px;
  padding: 8px;
  position: relative;
  background-color: var(--file-chit-background);
  max-height: 50px;
  max-width: 256px;
  transition: transform 0.3s ease;

  &.is-removing {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
    transition:
      transform 0.1s ease,
      opacity 0.1s ease;
  }

  .file-information {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    max-width: 84%;
    width: 100%;
    align-self: center;
  }

  .file-chit-close {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--file-chit-close-background);
    border-radius: 50%;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: auto;

    svg {
      color: var(--file-chit-close-color);
    }
  }

  .file-name {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .file-description {
    color: var(--file-chit-description-color);
    font-size: 11px;
    line-height: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.error .file-description {
    color: var(--main-danger-color);
  }
}

.file-chit-row.default .file-chit {
  .file-information {
    max-width: 80%;
  }

  &.editable .file-information {
    max-width: 74%;
  }
}

.file-chit-row.small .file-chit {
  max-width: 190px;

  .file-information {
    max-width: 78%;
  }

  &.editable .file-information {
    max-width: 64%;
  }
}

.file-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 8px;

  &.processing {
    background-color: var(--file-chit-processing);
  }
  &.uploading {
    background-color: var(--file-chit-uploading);
  }
  &.unknown {
    background-color: var(--file-chit-unknown);
  }
  &.docx {
    background-color: var(--file-chit-docx);
  }
  &.pptx {
    background-color: var(--file-chit-pptx);
  }
  &.xlsx {
    background-color: var(--file-chit-xlsx);
  }
  &.pdf {
    background-color: var(--file-chit-pdf);
  }
  &.txt {
    background-color: var(--file-chit-txt);
  }
}
