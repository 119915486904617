.login {
  height: 90vh;
  width: 100vw;
  display: grid;
  justify-items: center;
  align-content: center;
  text-align: center;

  .login-content {
    max-width: 512px;
    width: 100%;
  }

  button {
    margin-top: 0.5rem;
    padding: 0.8rem;
    height: auto;
    width: 100%;
    max-width: 100%;
  }

  .goldie-logo {
    justify-content: left;
    padding-left: 0;
  }

  .stryker-logo {
    justify-content: left;
    margin-bottom: 48px;
    padding: 0;

    svg {
      color: var(--main-text-color);
    }
  }
}
