.context-button {
  display: grid;
  grid-template-columns: 1fr 35px;
  background-color: var(--context-button-bg);
  align-items: center;
  padding: 1rem 1.2rem;
  border-radius: 25px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    background-color: var(--context-button-bg-hover);
  }

  &.selected {
    background-color: var(--context-button-selected);
  }

  &.selected:hover {
    background-color: var(--context-button-selected-hover);
  }

  h2 {
    font-size: 1.25rem;
  }

  p {
    font-size: 0.75rem;
    max-width: 95%;
  }
}
