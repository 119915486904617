.conversation-box {
  position: relative;
  align-items: center;
  width: 90%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;

  .conversation {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    background-color: var(--question-input-bg-color);
    border: var(--question-input-bg-color);
    border-radius: var(--question-input-border-radius);
    padding-left: 0.1em;
    padding-right: 0.4em;
    overflow: clip;
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);

    &.has-file-chits {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: var(--question-input-border-radius);
      border-bottom-right-radius: var(--question-input-border-radius);
      border-top: 1px solid var(--question-input-border-seperator);
    }
  }

  .conversation textarea {
    word-wrap: break-word;
    max-height: 350px;
    resize: none;
    background: none;
    border: none;
    color: var(--main-input-text-color);
    flex-grow: 1;
    line-height: 1.55em;
    height: 20px;
    transition: height 0s !important;
    scrollbar-color: var(--main-input-bg-color) var(--main-input-bg-color);
  }

  .conversation textarea::placeholder {
    color: #a5a5ac;
    font-size: 0.9rem;
  }

  .conversation button {
    cursor: pointer;
    display: flex;
    align-items: baseline;
    margin-left: auto;

    &:hover {
      background: none;
    }

    svg {
      transition: color 0.2s ease-out;
      color: #ffba30;

      path {
        stroke: #fff;
      }
    }

    &.-disabled,
    &.-disabled:hover {
      cursor: auto;

      svg {
        color: #505053;

        path {
          stroke: #9e9e9f;
        }
      }
    }
  }

  .multiline.conversation textarea {
    padding: 0.45em 0.25em 1em 1.3em;
    scrollbar-width: none;
    height: initial;
  }

  .multiline.conversation button {
    margin: auto 2px 2px 0;
  }

  .multiline.conversation textarea::-webkit-scrollbar-track {
    background: none;
    scrollbar-width: none;
  }

  .multiline.conversation textarea::-webkit-scrollbar-thumb {
    background-color: var(--main-input-bg-color);
    outline: 1px solid none;
    height: 100%;
    border-radius: 0;
  }
}

.input-character-limit {
  position: absolute;
  top: -1.3rem;
  right: 0.6rem;
  color: var(--main-input-text-color);
  font-size: 0.75rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-out;

  &.-show {
    opacity: 0.5;
    visibility: visible;
  }
}

.conversation-file-chits {
  width: 100%;
  background-color: var(--question-input-bg-color);
  border-top-left-radius: var(--question-input-border-radius);
  border-top-right-radius: var(--question-input-border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  opacity: 0;

  &.has-file-chits {
    opacity: 100;
    padding: 12px;
    transition: opacity 0.2s ease-out;
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
  }
}

.loading-updated-chits {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (min-width: 1024px) {
  .conversation-box {
    width: 100%;
  }
}
