.confirmation-dialog {
  .confirmation-dialog-text {
    text-align: center;
    font-size: 15px;
  }

  .ant-modal-footer {
    margin-top: 2rem;
  }
}
