.file-selector {
  h1 {
    margin: 0 0 20px;
    text-align: center;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 10px 0 25px;
    overflow-y: scroll;
    scrollbar-color: var(--modal-scrollbar-foreground)
      var(--modal-scrollbar-background);

    &::-webkit-scrollbar {
      width: 8px;
      background-color: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--modal-scrollbar-foreground);
      border: none !important;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-button:decrement,
    &::-webkit-scrollbar-button:increment {
      width: 0;
    }

    &.file-list {
      margin-bottom: 3rem;
      max-height: 45vh;
    }

    li {
      margin: 0.85rem 0;
    }
  }

  .all {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    overflow: hidden;

    label {
      color: var(--main-text-color);
    }
  }

  .delete-button {
    right: 58px !important;

    svg {
      margin-left: 1px;
    }
  }

  .delete-button:hover {
    color: var(--main-text-color) !important;
    background-color: var(--modal-button-background-hover) !important;
  }
}

.file-list .all .checkbox-selection .ant-checkbox-inner {
  border-color: var(--file-checkbox-border);
}

.file-list-item {
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.file-available.checkbox-selection label,
.file-unavailable.checkbox-selection label {
  cursor: pointer;
  transition: color 0.2s ease-out;
}

.file-list-item:hover label {
  color: var(--file-text-hover) !important;
}

.file-list-item:hover .file-unavailable label {
  color: var(--file-text-unavailable-hover) !important;
}

.file-available.checkbox-selection label {
  color: var(--file-text-available);
}

.file-unavailable.checkbox-selection label {
  color: var(--file-text-unavailable);
}

.file-available.checkbox-selection .ant-checkbox-inner {
  border-color: var(--file-checkbox-border);
}

.file-unavailable.checkbox-selection .ant-checkbox-inner {
  border-color: var(--file-text-unavailable);
}

.ant-modal.select-file-modal {
  .ant-modal-content {
    padding-right: 0px;
    padding-left: 0;
  }

  .ant-modal-footer {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 20px;
  }

  .warning {
    color: var(--main-danger-color);
    text-align: center;
    font-size: 0.8rem;
  }
  .info {
    color: var(--main-text-color-light);
    text-align: center;
    font-size: 0.8rem;
  }
}
