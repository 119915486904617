.model-selection {
  width: 100%;
  max-width: var(--center-column-width);

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--input-background-color) !important;
    border: none !important;
    color: var(--main-text-color) !important;
    width: 100% !important;
    min-height: 3rem;
    text-align: center;
    max-width: 100%;
    margin: 0 10px;

    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: var(--input-background-color-hover) !important;
      border: none !important;
    }

    span {
      display: block;
      width: 100%;
      text-align: center;
    }

    svg {
      margin-left: auto;
    }
  }
}

.gpt-model-selection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.gpt-model-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-items: center;

  .gpt-model-item {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .model-selection button {
    margin: 0;
  }
}
