.ant-modal {
  font-family: var(--font-body) !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  top: 0 !important;
  max-width: unset !important;
  height: 100vh !important;

  .ant-modal-content {
    background-color: var(--modal-background-color);
    color: var(--main-text-color);
    border-radius: 0 !important;
    padding: 25px 24px;
    height: 100%;
  }

  .ant-modal-round-button,
  .ant-modal-close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--modal-button-background);
    border-radius: 50% !important;
    height: 36px !important;
    width: 36px !important;
    top: 20px !important;
    right: 16px !important;

    &:hover {
      background: var(--modal-button-background-hover);
    }
  }

  .ant-modal-close-x {
    color: var(--main-text-color);
  }

  .ant-modal-header {
    background: none;
    align-items: center;
    margin: 0 0 20px;
  }

  .ant-modal-title {
    color: var(--main-text-color);
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .ant-modal-footer {
    display: flex;

    .ant-btn {
      width: 50%;
    }
  }

  .tiny {
    font-size: 0.8rem;
  }
}

/* sm */
@media (min-width: 640px) {
  .ant-modal {
    max-width: 418px !important;
    height: unset !important;
    top: 100px !important;
    padding-bottom: 24px;
    margin: 0 auto !important;

    .ant-modal-content {
      border-radius: 30px !important;
    }
  }
}
